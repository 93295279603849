import { FETCH_ORDERS, FETCH_TRADES, CHANGE_CURRENCY_ORDER } from "./actionTypes";
import { FETCH_ORDERS_URL, FETCH_TRADES_URL } from "../../helper/url_helper";
import api from "../../helper/api_helper";
import { toast } from "react-toastify";

export const fetchOrder = (jwttoken) => {
  return async (dispatch) => {
    try {
      api.defaults.headers.common["Authorization"] = `Bearer ${jwttoken}`;
      let res = await api.get(FETCH_ORDERS_URL);
      if (res.data.status === "SUCCESS") {
        // toast.success(res.data.message);
        dispatch({ type: FETCH_ORDERS, payload: res.data.payload });
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
};

export const fetchTrades = (jwttoken) => {
  return async (dispatch) => {
    try {
      api.defaults.headers.common["Authorization"] = `Bearer ${jwttoken}`;
      let res = await api.get(FETCH_TRADES_URL);
      if (res.data.status === "SUCCESS") {
        // toast.success(res.data.message);
        dispatch({ type: FETCH_TRADES, payload: res.data.payload });
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
};

export const changeCurrency = (currency) => {
  return async (dispatch) => {
    dispatch({ type: CHANGE_CURRENCY_ORDER, payload: currency });
  };
};
