import React, { useEffect } from 'react';
import { HistoryCard } from '../../../components/account/smallComponents.jsx';
import { Card, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { fetchTransaction } from '../../../store/transaction/action.jsx';
import { EmptyPageImage } from '../../../components/common/common.jsx';

export default function AccountTransaction() {
    const dispatch = useDispatch();
    const history = useHistory();
    const loginState = useSelector((state) => state.login);
    const transactionState = useSelector((state) => state.transaction);

    useEffect(() => {
        dispatch(fetchTransaction(loginState.jwttoken));
    }, []);

    return <>
        <div className="screen">
            <div className="main-content bg-light">
                <div className="mb-4">
                    <span class="d-flex align-items-center fw-semibold gap-2" onClick={() => { history.goBack() }}>
                        <img src="images/left-arrow.svg" alt="left-arrow" />Transaction History
                    </span>
                </div>
                <div className="d-flex gap-3">
                    <Card className="border-0 p-0 mb-3 w-50">
                        <Card.Body className="p-4 text-center">

                            <span className="d-block font-blue font-m mb-0">&#x20b9;{transactionState.balance}</span>
                            <span className="font-dark-grey font-m">Available Balance</span>

                        </Card.Body>
                    </Card>
                    <Card className="border-0 p-0 mb-3 w-50">
                        <Card.Body className="p-4 text-center">
                            <span className="d-block font-blue font-m mb-0">&#x20b9;{transactionState.balance_usd}</span>
                            <span className="font-dark-grey font-m">Available Balance</span>
                        </Card.Body>
                    </Card>
                </div>
                <div>
                    <div className="watch-list-box-pannel">
                        {((transactionState.transactions).length === 0) &&
                            <EmptyPageImage />
                        }

                        {transactionState.transactions.map((transaction) => {
                            return (
                                <Card className="border-0 p-0 mb-2" key={transaction.id}>
                                    <Card.Body className="py-2 px-3">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div>
                                                <span className="d-block font-m font-dark-grey mb-2">{transaction.added_on}</span>
                                                <span className="fw-semibold d-flex align-items-center"><img className="me-2" src="images/up-arrow-circle.svg" alt="up-arrow-circle" />{transaction.type}</span>
                                            </div>
                                            <div className="text-end">
                                                <span className="d-block fw-semibold mb-2">{(transaction.currency === "INR") ? "₹" : "$"}{transaction.amount}</span>
                                                <span class={`font-s ${(transaction.status === "complete") ? "label-green" : (transaction.status === "pending") ? "label-yellow" : "label-red"}`} > {transaction.status}</span>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            );
                        })}
                    </div>
                </div>
                <div className="transaction-buttons">
                    <Button className="deposit-btn me-2" onClick={() => { history.push("/account-deposite") }}>Deposit</Button>
                    <Button className="withdraw-btn" onClick={() => { history.push("/account-withdraw") }}>Withdraw</Button>
                </div>
            </div>

        </div >

    </>
}