import React from 'react';



export default function Splash() {
    return <>
        <div className="screen">
            <div className="splash-content bg-black p-0">
                <div className="splash-wrapper">
                    <img src="images/logo.png" alt="logo" />
                </div>
            </div>
        </div>
    </>
}