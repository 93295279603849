import React from "react";
import { Card } from "react-bootstrap";

export default function Notification() {
  return (
    <div className="screen mb-3">
      <div className="main-content bg-white">
        <div className="d-flex justify-content-between mb-3">
          <span class="d-flex align-items-center fw-semibold gap-3">
            <img src="images/left-arrow.svg" alt="left-arrow" />
            Notification
          </span>
          <span>
            <img src="images/search-icon.svg" alt="notification-search-icon" />
          </span>
        </div>
        <div className="today-notification pb-20">
          <span className="font-m font-dark-grey fw-bold">Today</span>
        </div>
        <Card className="border-0 p-0 mb-3 w-100">
          <Card.Body className="p-0">
            <div className="d-flex align-items-start">
              <div className="flex-shrink-0">
                <img
                  src="images/notification-green-arrow.svg"
                  alt="arrow-icon"
                />
              </div>
              <div className="flex-grow-1 pb-1 ms-4 border-bottom-1">
                <span className="d-block font-m mb-0 lh-2">
                  You received a payment of{" "}
                  <span className="font-blue">$780.1</span> from
                  <br /> Justin Westervelt
                </span>
                <span className="font-s">9:01am</span>
              </div>
            </div>
          </Card.Body>
        </Card>
        <Card className="border-0 p-0 mb-3 w-100">
          <Card.Body className="p-0">
            <div className="d-flex align-items-start">
              <div className="flex-shrink-0">
                <img
                  src="images/notification-green-right.svg"
                  alt="right-icon"
                />
              </div>
              <div className="flex-grow-1 pb-1 ms-4 border-bottom-1">
                <span className="d-block font-m mb-0 lh-2">
                  Lindsey Culhane requested a<br /> payment of{" "}
                  <span className="font-blue">$780.1</span>
                </span>
                <span className="font-s">9:01am</span>
              </div>
            </div>
          </Card.Body>
        </Card>
        <div className="yesterday-notification pb-20">
          <span className="font-m font-dark-grey fw-bold">Yesterday</span>
        </div>
        <Card className="border-0 p-0 mb-3 w-100">
          <Card.Body className="p-0">
            <div className="d-flex align-items-start">
              <div className="flex-shrink-0">
                <img
                  src="images/lc-icon.svg"
                  alt="lc-icon"
                />
              </div>
              <div className="flex-grow-1 pb-1 ms-4 border-bottom-1">
                <span className="d-block font-m mb-0 lh-2">
                  Lindsey Culhane requested a<br /> payment of{" "}
                  <span className="font-blue">$780.1</span>
                </span>
                <span className="font-s">9:01am</span>
              </div>
            </div>
          </Card.Body>
        </Card>
        <Card className="border-0 p-0 mb-3 w-100">
          <Card.Body className="p-0">
            <div className="d-flex align-items-start">
              <div className="flex-shrink-0">
                <img
                  src="images/cb-icon.svg"
                  alt="cb-icon"
                />
              </div>
              <div className="flex-grow-1 pb-1 ms-4 border-bottom-1">
                <span className="d-block font-m mb-0 lh-2">
                  Lindsey Culhane requested a<br /> payment of{" "}
                  <span className="font-blue">$780.1</span>
                </span>
                <span className="font-s">9:01am</span>
              </div>
            </div>
          </Card.Body>
        </Card>
        <Card className="border-0 p-0 mb-3 w-100">
          <Card.Body className="p-0">
            <div className="d-flex align-items-start">
              <div className="flex-shrink-0">
                <img
                  src="images/pink-down-arrow.svg"
                  alt="pink-down-arrow"
                />
              </div>
              <div className="flex-grow-1 pb-1 ms-4 border-bottom-1">
                <span className="d-block font-m mb-0 lh-2">
                  Lindsey Culhane requested a<br /> payment of{" "}
                  <span className="font-blue">$780.1</span>
                </span>
                <span className="font-s">9:01am</span>
              </div>
            </div>
          </Card.Body>
        </Card>
        <Card className="border-0 p-0 mb-3 w-100">
          <Card.Body className="p-0">
            <div className="d-flex align-items-start">
              <div className="flex-shrink-0">
                <img
                  src="images/lc-blue.svg"
                  alt="lc-blue-icon"
                />
              </div>
              <div className="flex-grow-1 pb-1 ms-4 border-bottom-1">
                <span className="d-block font-m mb-0 lh-2">
                  Lindsey Culhane requested a<br /> payment of{" "}
                  <span className="font-blue">$780.1</span>
                </span>
                <span className="font-s">9:01am</span>
              </div>
            </div>
          </Card.Body>
        </Card>
        <Card className="border-0 p-0 mb-3 w-100">
          <Card.Body className="p-0">
            <div className="d-flex align-items-start">
              <div className="flex-shrink-0">
                <img
                  src="images/cancle-icon.svg"
                  alt="cancle-icon"
                />
              </div>
              <div className="flex-grow-1 pb-1 ms-4 border-bottom-1">
                <span className="d-block font-m mb-0 lh-2">
                  Lindsey Culhane requested a<br /> payment of{" "}
                  <span className="font-blue">$780.1</span>
                </span>
                <span className="font-s">9:01am</span>
              </div>
            </div>
          </Card.Body>
        </Card>
        <Card className="border-0 p-0 mb-3 w-100">
          <Card.Body className="p-0">
            <div className="d-flex align-items-start">
              <div className="flex-shrink-0">
                <img
                  src="images/questionmark.svg"
                  alt="questionmark"
                />
              </div>
              <div className="flex-grow-1 pb-1 ms-4">
                <span className="d-block font-m mb-0 lh-2">
                  Unknown Notification
                </span>
                <span className="font-s">9:01am</span>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};


