import { FETCH_TRANSACTION } from "./actionTypes";
let initialState = {
  "transactions": [],
  "balance": 0,
  "balance_usd": 0
};
const transaction = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TRANSACTION:
      return action.payload;
    default:
      return state;
  }
};
export default transaction;
