import React from "react";
import Form from "react-bootstrap/Form";
import { Alert, Card } from "react-bootstrap";

export default function SearchBarHover() {
  return (
    <div className="screen mb-3">
      <div className="main-content bg-light">
        <span class="d-flex align-items-center fw-semibold gap-3 mb-30">
          <img src="images/left-arrow.svg" alt="left-arrow" />
          Add Script
        </span>

        <Form className="search-items mb-2">
          <Form.Group>
            <div className="search-icon">
              <img class="right-icon" src="images/search.svg" />
            </div>
            <Form.Control
              className="search-form-control"
              type="text"
              placeholder="Search..."
            />
            <div className="clear-label">
              <span className="font-s">Clear</span>
            </div>
          </Form.Group>
        </Form>

        <Card className="border-0 p-0 mb-2 w-100">
          <Card.Body className="p-3">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                  <span className="font-m label-blue p-1">MCX</span>
                </div>
                <div className="flex-grow-1 ms-2">
                  <span className="font-m mb-0 lh-1">NATURALGASM -</span>
                  <span className="font-m font-dark-grey ms-1">14 SEP 23 </span>
                </div>
              </div>
              <img src="images/plus-icon.svg" alt="plus-icon" />
            </div>
          </Card.Body>
        </Card>
        <Card className="border-0 p-0 mb-2 w-100">
          <Card.Body className="p-3">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                  <span className="font-m label-blue p-1">MCX</span>
                </div>
                <div className="flex-grow-1 ms-2">
                  <span className="font-m mb-0 lh-1">NATURALGASM -</span>
                  <span className="font-m font-dark-grey ms-1">14 SEP 23 </span>
                </div>
              </div>
              <img src="images/plus-icon.svg" alt="plus-icon" />
            </div>
          </Card.Body>
        </Card>
        <Card className="border-0 p-0 mb-2 w-100">
          <Card.Body className="p-3">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                  <span className="font-m label-red p-1">FNO</span>
                </div>
                <div className="flex-grow-1 ms-2">
                  <span className="font-m mb-0 lh-1">NATURALGASM -</span>
                  <span className="font-m font-dark-grey ms-1">14 SEP 23 </span>
                </div>
              </div>
              <img src="images/plus-icon.svg" alt="plus-icon" />
            </div>
          </Card.Body>
        </Card>
        <Card className="border-0 p-0 mb-2 w-100">
          <Card.Body className="p-3">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                  <span className="font-m label-blue p-1">MCX</span>
                </div>
                <div className="flex-grow-1 ms-2">
                  <span className="font-m mb-0 lh-1">NATURALGASM -</span>
                  <span className="font-m font-dark-grey ms-1">14 SEP 23 </span>
                </div>
              </div>
              <img src="images/plus-icon.svg" alt="plus-icon" />
            </div>
          </Card.Body>
        </Card>
        <Card className="border-0 p-0 mb-2 w-100">
          <Card.Body className="p-3">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                  <span className="font-m label-red p-1">FNO</span>
                </div>
                <div className="flex-grow-1 ms-2">
                  <span className="font-m mb-0 lh-1">NATURALGASM -</span>
                  <span className="font-m font-dark-grey ms-1">14 SEP 23 </span>
                </div>
              </div>
              <img src="images/plus-icon.svg" alt="plus-icon" />
            </div>
          </Card.Body>
        </Card>
        <Card className="border-0 p-0 mb-2 w-100">
          <Card.Body className="p-3">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                  <span className="font-m label-red p-1">FNO</span>
                </div>
                <div className="flex-grow-1 ms-2">
                  <span className="font-m mb-0 lh-1">NATURALGASM -</span>
                  <span className="font-m font-dark-grey ms-1">14 SEP 23 </span>
                </div>
              </div>
              <img src="images/plus-icon.svg" alt="plus-icon" />
            </div>
          </Card.Body>
        </Card>
        <Card className="border-0 p-0 mb-2 w-100">
          <Card.Body className="p-3">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                  <span className="font-m label-blue p-1">MCX</span>
                </div>
                <div className="flex-grow-1 ms-2">
                  <span className="font-m mb-0 lh-1">NATURALGASM -</span>
                  <span className="font-m font-dark-grey ms-1">14 SEP 23 </span>
                </div>
              </div>
              <img src="images/plus-icon.svg" alt="plus-icon" />
            </div>
          </Card.Body>
        </Card>
        <Card className="border-0 p-0 mb-2 w-100">
          <Card.Body className="p-3">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                  <span className="font-m label-blue p-1">MCX</span>
                </div>
                <div className="flex-grow-1 ms-2">
                  <span className="font-m mb-0 lh-1">NATURALGASM -</span>
                  <span className="font-m font-dark-grey ms-1">14 SEP 23 </span>
                </div>
              </div>
              <img src="images/plus-icon.svg" alt="plus-icon" />
            </div>
          </Card.Body>
        </Card>
        <Card className="border-0 p-0 mb-2 w-100">
          <Card.Body className="p-3">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                  <span className="font-m label-blue p-1">MCX</span>
                </div>
                <div className="flex-grow-1 ms-2">
                  <span className="font-m mb-0 lh-1">NATURALGASM -</span>
                  <span className="font-m font-dark-grey ms-1">14 SEP 23 </span>
                </div>
              </div>
              <img src="images/plus-icon.svg" alt="plus-icon" />
            </div>
          </Card.Body>
        </Card>
        <div>
          <Alert className="bg-green" dismissible>
            <Alert.Heading>Added</Alert.Heading>
          </Alert>
        </div>
      </div>
    </div>
  );
};
