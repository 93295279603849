import React from "react";
import Table from "react-bootstrap/Table";
import { Card, Row, Col } from "react-bootstrap";

export default function ReferAndEarn() {
  return (
    <div className="screen mb-3">
      <div className="main-content bg-light">
        <span class="d-flex align-items-center fw-semibold gap-3 mb-30">
          <img src="images/left-arrow.svg" alt="left-arrow" />
          Refer and earn
        </span>

        <Card className="border-0 p-0 mb-2 w-100">
          <Card.Body className="p-3">
            <div className="d-flex align-items-center justify-content-between mb-4">
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                  <span><img src="images/jlp-logo.svg" alt="jlp-logo" /></span>
                </div>
                <div className="flex-grow-1 ms-3">
                  <span className="font-m d-block fw-semibold">Jean-loup Autret</span>
                  <span className="font-s">Issy Les Moulineaux</span>
                </div>
              </div>
            </div>
            <div className="trading-balance-card bg-white d-flex gap-2">
              <div className="refer-box text-center bg-light"><span className="d-block text-black font-s">Total Referrals</span><span className="text-black fw-bold">#32</span></div>

              <div className="refer-box2 text-center bg-light"><span className="d-block text-black font-s">USD</span><span className="text-black fw-bold">1000</span></div>
              <div className="refer-box3 text-center bg-light"><span className="d-block text-black font-s">INR</span><span className="text-black fw-bold">1000 INR</span></div>
            </div>
          </Card.Body>
        </Card>


        {/*Refer Table*/}

        <Table className="refer-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>USD</th>
              <th>INR</th>
              <th>Member</th>
            </tr>
          </thead>
          <tbody>

            <tr>
              <td>Lachlan Walker</td>
              <td>$400</td>
              <td>₹1000</td>
              <td>5</td>
            </tr>
            <tr>
              <td>Lachlan Walker</td>
              <td>$400</td>
              <td>₹1000</td>
              <td>5</td>
            </tr>
            <tr>
              <td>Isabella Morton</td>
              <td>$10</td>
              <td>₹4000</td>
              <td>3</td>
            </tr>
            <tr>
              <td>Lucas White</td>
              <td>$10</td>
              <td>₹6000</td>
              <td>7</td>
            </tr>
            <tr>
              <td>Shirley Beck</td>
              <td>$10</td>
              <td>₹2000</td>
              <td>5</td>
            </tr>
            <tr>
              <td>nicolas cage</td>
              <td>$10</td>
              <td>₹500</td>
              <td>2</td>
            </tr>
            <tr>
              <td>jackie chan</td>
              <td>$10</td>
              <td>₹6000</td>
              <td>6</td>
            </tr>
          </tbody>
        </Table>
      </div>
    </div>
  );
};

