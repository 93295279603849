import { FETCH_PROFILE } from "./actionTypes";
let initialState = {

};
const profile = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PROFILE:
      return action.payload;
    default:
      return state;
  }
};
export default profile;
