import { WatchlistBox } from '../../components/watchlist/smallComponents.jsx';
import { Card, Tab, Tabs, Nav, Form, Alert } from 'react-bootstrap';


export default function WatchlistSuccessful() {
    return <>
        <div className="screen mb-3">
            <div className="main-content bg-light">
                <div>
                    <Card className="border-0 bg-transparent p-0 mb-3">
                        <Card.Body className="d-flex justify-content-between align-items-center p-0">
                            <div className="col-6">
                                <span className="fw-semibold">Watchlist Crypto</span>
                            </div>
                            <div className="col-5">
                                <Form>
                                    <Form.Group controlId="formGroupPassword">
                                        <Form.Control className="search-form-control" type="text" placeholder="Search" />
                                        <div class="search-icon"><img class="right-icon" src="images/search.svg" /></div>
                                    </Form.Group>
                                </Form>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
                <div className="d-flex gap-3">
                    <Card className="border-0 p-0 mb-3 w-50">
                        <Card.Body className="d-flex align-items-center p-3">
                            <img src="images/bitcoin.svg" alt="bitcoin" />
                            <div className="ms-3">
                                <span className="d-block font-m mb-1">Crypto/BTC</span>
                                <small className="font-red font-s">-132,5%<img className="ms-2" src="images/red-arrow.svg" alt="red-arrow" /></small>
                            </div>
                        </Card.Body>
                    </Card>
                    <Card className="border-0 p-0 mb-3 w-50">
                        <Card.Body className="d-flex align-items-center p-3">
                            <img src="images/usdt-icon.svg" alt="usdt-icon" />
                            <div className="ms-3">
                                <span className="d-block font-m mb-1">Crypto/USDT</span>
                                <small className="font-green font-s">-132,5%<img className="ms-2" src="images/green-arrow.svg" alt="green-arrow" /></small>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
                <div>
                    <Tab.Container id="tabs-example" defaultActiveKey="crypto">
                        <Nav className="d-flex justify-content-between watchlist-tab" variant="tabs">
                            <Nav.Item>
                                <Nav.Link eventKey="crypto">Crypto</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="forex">Forex</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="fno">FNO</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="mcx">MCX</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="us">US</Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <Tab.Content className="watch-list-box-pannel">
                            <Tab.Pane eventKey="crypto">
                                <WatchlistBox />
                            </Tab.Pane>
                            <Tab.Pane eventKey="forex">
                                <WatchlistBox />
                            </Tab.Pane>
                            <Tab.Pane eventKey="fno">
                                <WatchlistBox />
                            </Tab.Pane>
                            <Tab.Pane eventKey="mcx">
                                <WatchlistBox />
                            </Tab.Pane>
                            <Tab.Pane eventKey="us">
                                <WatchlistBox />
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </div>
                <div>
                    <Alert className="bg-green" dismissible>
                        <Alert.Heading>Order Successfully Place</Alert.Heading>
                    </Alert>
                </div>

            </div>
            <div className="footer">
                <Tab.Container id="tabs-example" defaultActiveKey="watchlist">
                    <Nav className="d-flex justify-content-between footer-tabs" variant="tabs">
                        <Nav.Item>
                            <Nav.Link eventKey="market"><div className="mb-2"><img src="images/market.svg" alt="market" /></div>Market</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="watchlist"><div className="mb-2"><img src="images/watchlist.svg" alt="watchlist" /></div>Watchlist</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="portfolio"><div className="mb-2"><img src="images/portfolio.svg" alt="portfolio" /></div>Portfolio</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="order"><div className="mb-2"><img src="images/order.svg" alt="order" /></div>Order</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="account"><div className="mb-2"><img src="images/account.svg" alt="account" /></div>Account</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <Tab.Content>
                        <Tab.Pane eventKey="market">
                            <div>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="watchlist">
                            <div>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="portfolio">
                            <div>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="order">
                            <div>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="account">
                            <div>
                            </div>
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </div>
        </div>
        {/* section one */}
        {/* <section className='card d-flex justify-content-between'>
            <div>
                <span className='font-m'>Watchlist Crypto</span>
            </div>
            <div>
                <div className='bg-white px-20px py-5px font-dark-grey font-s btn-cy'>Search</div>
            </div>
        </section> */}
        {/* section two */}
        {/* <section className='d-flex justify-content-between mt-10px'>
                <IndexBox className="mr-20px flex-grow-1" />
                <IndexBox className="flex-grow-1" />
            </section> */}

        {/* section three */}
        {/* <section className='mt-10px'>
            <div className='card d-flex justify-content-between py-10px'>
                <RoundedButton classes="bg-blue font-white flex-grow-1">FNO</RoundedButton>
                <RoundedButton classes="bg-light flex-grow-1">MCX</RoundedButton>
                <RoundedButton classes="bg-light flex-grow-1">FX</RoundedButton>
                <RoundedButton classes="bg-light flex-grow-1">CRYPTO</RoundedButton>
                <RoundedButton classes="bg-light flex-grow-1">US</RoundedButton>
            </div>
            <div className=''>
                <WatchlistBox />
                <div className='mt-10px'></div>
                <WatchlistBox />
                <div className='mt-10px'></div>
                <WatchlistBox />
                <div className='mt-10px'></div>
                <WatchlistBox />
            </div>
        </section>
    </div > */}
    </>
}