import { FETCH_ORDERS, FETCH_TRADES, CHANGE_CURRENCY_ORDER } from "./actionTypes";
let initialState = {
  "currency": "INR",
  orders: {
    "pending": {
      "INR": [],
      "USD": []
    },
    "executed": {
      "INR": [],
      "USD": []
    }
  },
  trades: {
    "INR": [],
    "USD": []
  }
};
const order = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ORDERS:
      return {
        ...state,
        orders: action.payload
      };
    case FETCH_TRADES:
      return {
        ...state,
        trades: action.payload
      };
    case CHANGE_CURRENCY_ORDER:
      return {
        ...state,
        currency: action.payload
      };
    default:
      return state;
  }
};
export default order;
