import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { routes, publicRoutes } from './data';
import { useSelector } from 'react-redux';
// import { BottomSheetWatchlist } from '../screens/private/watchlist/BottomSheetWatchlist';
import Splash from '../screens/public/Splash.jsx';

function App() {
    const loginState = useSelector((state) => state.login);
    const [showSplash, setShowSplash] = useState(true); // State to control Splash visibility
    // const loginState = useSelector((state) => state.login);

    useEffect(() => {
        // Hide Splash after 5 seconds
        const timer = setTimeout(() => {
            setShowSplash(false);
        }, 2400);

        // Clean up timer on component unmount
        return () => clearTimeout(timer);
    }, []); // Empty dependency array ensures this effect runs only once on mount


    return (
        <Router>
            {/* Render Splash if showSplash is true */}
            {showSplash && <Splash />}
            <div style={(showSplash) ? { "display": "none" } : {}}>
                <Switch>
                    {/* Render Route components for each route */}

                    {loginState.jwttoken && routes.map((route, index) => (
                        <Route key={index} path={route.path} component={route.component} />
                    ))}

                    {(!loginState.jwttoken) && publicRoutes.map((route, index) => (
                        <Route key={index} path={route.path} component={route.component} />
                    ))}
                </Switch>
            </div>
        </Router>
    );
}

export default App;
