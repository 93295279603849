import { ExecutedOrderBox } from '../../components/orders/smallComponents.jsx';
import { PendingOrderBox } from '../../components/orders/smallComponents.jsx';
import { TradeBox } from '../../components/orders/smallComponents.jsx';
import { Card, Tab, Nav, Dropdown, DropdownButton, Form } from 'react-bootstrap';

export default function ExecutedOrders() {
    return <>
        <div className="screen mb-3">
            <div className="main-content bg-light">
                <div>
                    <Card className="border-0 bg-transparent p-0 mb-3">
                        <Card.Body className="d-flex justify-content-between align-items-center p-0">
                            <div className="col-6">
                                <span className="fw-semibold">Orders</span>
                            </div>
                            <div className="col-5">
                                <Tab.Container id="tabs-example" defaultActiveKey="forex">
                                    <Nav className="d-flex justify-content-end orders-tab" variant="tabs">
                                        <Nav.Item>
                                            <Nav.Link eventKey="inr">INR</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="forex">Forex</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="inr">

                                        </Tab.Pane>
                                        <Tab.Pane eventKey="forex">

                                        </Tab.Pane>
                                    </Tab.Content>
                                </Tab.Container>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
                <div>
                    <Tab.Container id="tabs-example" defaultActiveKey="executed">
                        <div className="d-flex align-items-center justify-content-between mb-16">
                            <Nav className="d-flex justify-content-between watchlist-tab mb-0" variant="tabs">
                                <Nav.Item>
                                    <Nav.Link eventKey="pending">Pending</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="executed">Executed</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="trades">Trades</Nav.Link>
                                </Nav.Item>

                            </Nav>
                            <DropdownButton className="executed-dropdown" id="dropdown-basic-button" title="Day 1">
                                <Dropdown.Item href="#/action-1">Day 2</Dropdown.Item>
                                <Dropdown.Item href="#/action-2">Day 3</Dropdown.Item>
                                <Dropdown.Item href="#/action-3">Day 4</Dropdown.Item>
                            </DropdownButton>
                        </div>
                        <Form className="search-items mb-16">
                            <Form.Group>
                                <div class="search-icon"><img class="right-icon" src="images/search.svg" /></div>
                                <Form.Control className="search-form-control" type="text" placeholder="Search..." />

                            </Form.Group>
                        </Form>
                        <Tab.Content>
                            <Tab.Pane eventKey="pending">
                                <PendingOrderBox />
                            </Tab.Pane>
                            <Tab.Pane eventKey="executed">
                                <ExecutedOrderBox />
                            </Tab.Pane>
                            <Tab.Pane eventKey="trades">
                                <TradeBox />
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </div>
            </div>
            <div className="footer">
                <Tab.Container id="tabs-example" defaultActiveKey="order">
                    <Nav className="d-flex justify-content-between footer-tabs" variant="tabs">
                        <Nav.Item>
                            <Nav.Link eventKey="market"><div className="mb-2"><img src="images/market.svg" alt="market" /></div>Market</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="watchlist"><div className="mb-2"><img src="images/watchlist.svg" alt="watchlist" /></div>Watchlist</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="portfolio"><div className="mb-2"><img src="images/portfolio.svg" alt="portfolio" /></div>Portfolio</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="order"><div className="mb-2"><img src="images/order.svg" alt="order" /></div>Order</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="account"><div className="mb-2"><img src="images/account.svg" alt="account" /></div>Account</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <Tab.Content>
                        <Tab.Pane eventKey="market">
                            <div>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="watchlist">
                            <div>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="portfolio">
                            <div>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="order">
                            <div>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="account">
                            <div>
                            </div>
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </div>
        </div>
        {/* <div className=''>
            <ExecutedOrderBox bgClass="bg-white" />
            <div className='mt-10px'></div>
            <ExecutedOrderBox bgClass="bg-blue-light" />
            <div className='mt-10px'></div>
            <ExecutedOrderBox bgClass="bg-white" />
            <div className='mt-10px'></div>
            <ExecutedOrderBox bgClass="bg-white" />
        </div> */}
    </>
}