import { FETCH_BILL_HISTORY } from "./actionTypes";
let initialState = {
  "history": []
};
const bill = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BILL_HISTORY:
      return {
        "history": action.payload
      };
    default:
      return state;
  }
};
export default bill;
