import { FETCH_PORTFOLIO, CHANGE_PORTFOLIO_CURRENCY } from "./actionTypes";
let initialState = {
  "currency": "INR",
  positions: {
    "USD": [],
    "INR": []
  },
  "balance": "0.00",
  "used_balance": "0.00",
  "cnc_margin_req": "0.00",
  "balance_usd": "0.00",
  "used_balance_usd": "0.00",
  "cnc_margin_req_usd": "0.00"
};
const portfolio = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PORTFOLIO:
      return { ...state, ...action.payload };
    case CHANGE_PORTFOLIO_CURRENCY:
      return { ...state, "currency": action.payload };
    default:
      return state;
  }
};
export default portfolio;
