export const LOGIN_URL = "/clientLogin";
export const FETCH_GAINERS_URL = "/client/market/gainers";
export const FETCH_LOOSERS_URL = "/client/market/loosers";
export const FETCH_WATCHLIST_URL = "/client/watchlist/fetch";
export const SEARCH_INSTRUMENT_URL = "/client/watchlist/search";
export const ADD_WATCHLIST_URL = "/client/watchlist/add";
export const REMOVE_WATCHLIST_URL = "/client/watchlist/delete";
export const FETCH_PORTFOLIO_URL = "/client/position/fetch";
export const FETCH_PROFILE_URL = "/client/profile/fetch";
export const FETCH_LEDGER_MASTER_URL = "/client/ledgerMaster";
export const FETCH_CURRENCY_RATES_URL = "/client/currency/fetchCurrencyRate";
export const FETCH_MARGIN_URL = "/client/order/fetchMargin";
export const FETCH_ORDERS_URL = "/client/order/fetch";
export const FETCH_TRADES_URL = "/client/trade/fetch";
export const PLACE_ORDER_URl = "/client/order/push";
export const FETCH_TRANSACTION_URL = "/client/fund/fetchTransactions";
export const CHANGE_PASSWORD_URL = "/client/profile/changePassword";
export const FETCH_REJECTION_LOGS_URL = "/client/logs/rejection";
export const FETCH_BILL_HISTORY_URL = "/client/bill/fetchBillDateHistory";
export const FETCH_BILL_PDF_URL = "/client/bill/getBill";
export const FETCH_LEADERBOARD_URL = "/client/referral/leaderBoard";
export const SEND_OTP_SIGNUP_URL = "/sendOtp";
export const SIGNUP_URL = "/signupTradeKaro";
export const FETCH_INDEXES_URL = "/client/market/indexes";
export const CLOSE_ALL_POSITIONS_URL = "/client/position/closeAll";
export const CANCEL_ORDER_URL = "/client/order/cancel";
export const DEPOSIT_URL = "/client/fund/deposite";
export const WITHDRAW_URL = "/client/fund/generateWithdrawRequest";
export const FORGOT_PASSWORD_URL = "/forgotPassword";
export const VERIFY_PASSWORD_URL = "/verifyPassword";
export const RESET_PASSWORD_URL = "/resetPassword";
