import React from "react";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import { Card } from "react-bootstrap";

export default function LeaderBoard() {
  return (
    <div className="screen mb-3">
      <div className="main-content bg-light">
        <span class="d-flex align-items-center fw-semibold gap-3 mb-30">
          <img src="images/left-arrow.svg" alt="left-arrow" />
          Refer and earn
        </span>

        <Card className="border-0 p-0 mb-2 w-100">
          <Card.Body className="p-3">
            <div className="d-flex align-items-center justify-content-between mb-4">
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                  <span>
                    <img src="images/jlp-logo.svg" alt="jlp-logo" />
                  </span>
                </div>
                <div className="flex-grow-1 ms-3">
                  <span className="font-m d-block fw-semibold">
                    Jean-loup Autret
                  </span>
                  <span className="d-block mb-2 font-s">
                    Issy Les Moulineaux
                  </span>
                  <span className="border-radius p-2 mt-1 bg-light font-s lh-1">
                    <img
                      src="images/green-fill-arrow.svg"
                      alt="green-arrow"
                      className="me-1"
                    />
                    +1
                  </span>
                </div>
              </div>
            </div>
           {/*<div className="d-flex gap-2">
              <Card className="border-0 p-0 mb-3">
                <Card.Body className="p-4 text-center bg-light">
                  <span className="d-block text-black font-s">
                    Total Referrals
                  </span>
                  <span className="text-black">#32</span>
                </Card.Body>
              </Card>
              <Card className="border-0 p-0 mb-3">
                <Card.Body className="p-4 text-center bg-light">
                  <span className="d-block text-black font-s">USD</span>
                  <span className="text-black">1000</span>
                </Card.Body>
              </Card>
              <Card className="border-0 p-0 mb-3">
                <Card.Body className="p-4 text-center bg-light">
                  <span className="d-block text-black font-s">INR</span>
                  <span className="text-black">1000 INR</span>
                </Card.Body>
              </Card>
            </div>*/}
            <div className="trading-balance-card bg-white d-flex gap-2">
              <div className="refer-box text-center bg-light"><span className="d-block text-black font-s">Total Referrals</span><span className="text-black fw-bold">#32</span></div>

              <div className="refer-box2 text-center bg-light"><span className="d-block text-black font-s">USD</span><span className="text-black fw-bold">1000</span></div>
              <div className="refer-box3 text-center lable-bg-blue"><span className="d-block text-white font-s">INR</span><span className="text-white fw-bold">1000 INR</span></div>
            </div>
          </Card.Body>
        </Card>

        {/*Refer Table*/}

        <Table className="Leader-board-table">
          <thead>
            <tr>
              <th width="20%">Rank</th>
              <th width="50%">Username</th>
              <th width="30%">Earning</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <span className="rank-label">1</span>
                <img
                  className="ms-1"
                  src="images/green-fill-arrow.svg"
                  alt="arrow-icon"
                />
              </td>
              <td>
                <span className="">
                  <img
                    src="images/cb-icon.svg"
                    alt="cb-icon"
                    className="me-2"
                  />
                  Crystal Bennett
                </span>
              </td>
              <td>30 USDT</td>
            </tr>
            <tr>
              <td>
                <span className="rank-label">2</span>
                <img
                  className="ms-1"
                  src="images/red-fill-arrow.svg"
                  alt="arrow-icon"
                />
              </td>
              <td>
                <span>
                  <img
                    src="images/lw-logo.svg"
                    alt="lw-icon"
                    className="me-2"
                  />
                  Lachlan Walker
                </span>
              </td>
              <td>10.0 USDT</td>
            </tr>
            <tr>
              <td className="lable-bg-blue">
                <span className="rank-label bg-white text-black">3</span>
                <img
                  className="ms-1"
                  src="images/green-fill-arrow.svg"
                  alt="arrow-icon"
                />
              </td>
              <td className="lable-bg-blue">
                <span>
                  <img
                    src="images/jlp-white.svg"
                    alt="lw-icon"
                    className="me-2"
                  />
                  Lachlan Walker
                </span>
              </td>
              <td className="lable-bg-blue">10.0 USDT</td>
            </tr>
            <tr>
              <td>
                <span className="rank-label">4</span>
                <img
                  className="ms-1"
                  src="images/green-fill-arrow.svg"
                  alt="arrow-icon"
                />
              </td>
              <td>
                <span>
                  <img
                    src="images/cb-purple.svg"
                    alt="cb-purple"
                    className="me-2"
                  />
                  Isabella Morton
                </span>
              </td>
              <td>12 USDT</td>
            </tr>
            <tr>
              <td>
                <span className="rank-label">5</span>
                <img
                  className="ms-1"
                  src="images/red-fill-arrow.svg"
                  alt="arrow-icon"
                />
              </td>
              <td>
                <span>
                  <img
                    src="images/lw-green.svg"
                    alt="cb-purple"
                    className="me-2"
                  />
                  Lucas White
                </span>
              </td>
              <td>12 USDT</td>
            </tr>
            <tr>
              <td>
                <span className="rank-label">6</span>
                <img
                  className="ms-1"
                  src="images/light-green-fill-arrow.svg"
                  alt="arrow-icon"
                />
              </td>
              <td>
                <span>
                  <img
                    src="images/sb-red.svg"
                    alt="cb-purple"
                    className="me-2"
                  />
                  Shirley Beck
                </span>
              </td>
              <td>15 USDT</td>
            </tr>
            <tr>
              <td>
                <span className="rank-label">7</span>
                <img
                  className="ms-1"
                  src="images/yellow-fill-arrow.svg"
                  alt="arrow-icon"
                />
              </td>
              <td>
                <span>
                  <img
                    src="images/jj-logo.svg"
                    alt="cb-purple"
                    className="me-2"
                  />
                  Jonna Jinton
                </span>
              </td>
              <td>15 USDT</td>
            </tr>
            <tr>
              <td>
                <span className="rank-label">8</span>
                <img
                  className="ms-1"
                  src="images/yellow-fill-arrow.svg"
                  alt="arrow-icon"
                />
              </td>
              <td>
                <span>
                  <img
                    src="images/cb-purple.svg"
                    alt="cb-purple"
                    className="me-2"
                  />
                  jackie chan
                </span>
              </td>
              <td>15 USDT</td>
            </tr>
            <tr>
              <td>
                <span className="rank-label">9</span>
                <img
                  className="ms-1"
                  src="images/yellow-fill-arrow.svg"
                  alt="arrow-icon"
                />
              </td>
              <td>
                <span>
                  <img
                    src="images/nc-logo.svg"
                    alt="cb-purple"
                    className="me-2"
                  />
                  nicolas cage
                </span>
              </td>
              <td>15 USDT</td>
            </tr>
            <tr>
              <td>
                <span className="rank-label">10</span>
                <img
                  className="ms-1"
                  src="images/yellow-fill-arrow.svg"
                  alt="arrow-icon"
                />
              </td>
              <td>
                <span>
                  <img
                    src="images/cb-purple.svg"
                    alt="cb-purple"
                    className="me-2"
                  />
                  jill dasilva
                </span>
              </td>
              <td>15 USDT</td>
            </tr>
          </tbody>
        </Table>
        <Card className="border-0 p-0 mb-3">
          <Card.Body className="p-3 text-center bg-white ">
            <span className="text-black font-l fw-bold">
              Refer & Earn more
            </span>
            <span className="d-block font-s my-2 text-black">
              <span className="font-blue font-s">Get 5USDT</span> Promo code for
              new accounts!
            </span>

            <Form className="search-items mb-2">
              <Form.Group>
                <Form.Control
                  className="search-form-control bg-grey"
                  type="text"
                  placeholder="0x00AsnkJUI0245x0hgy2"
                />
                <div className="copy-icon">
                  <img class="copy-icon-img" src="images/copy-icon.svg" />
                </div>
              </Form.Group>
            </Form>
            <div class="mt-3">
              <button type="button" class="signin-btn w-100 btn btn-primary">
                <span className="d-flex align-items-center justify-content-center">
                  <img
                    src="images/upload-icon.svg"
                    alt="logout-icon"
                    className="me-2"
                  ></img>
                  Share
                </span>
              </button>
            </div>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};


