import React, { useState, useEffect } from 'react';
import { Card, Button, Offcanvas, Table } from 'react-bootstrap';
import { useSelector, useDispatch } from "react-redux";
import { changeStatusSheetWatchlist } from "../../../store/bottomSheetWatchlist/action";
import { timestampToDateTime } from '../../../helper/general_helper';
import { updateInstrumentPushOrder } from '../../../store/pushOrder/action.jsx';
import { useHistory } from 'react-router-dom';
// import BottomSheetWatchlist from './';

// import { convertDateFormat } from '../../../helper/general_helper';
// import BottomSheet from '../common/BottomSheet';
export function BottomSheetWatchlist() {
    const history = useHistory();
    const dispatch = useDispatch();
    // const pushOrderState = useSelector((state) => state.pushOrder);
    const sheetState = useSelector((state) => state.bottomSheetWatchlist);
    const socketState = useSelector((state) => state.socket);
    const [depthData, setDepthData] = useState({ "total_buy_qty": 0, "total_sell_qty": 0, "buy_width_classes": ["", "", "", "", ""], "sell_width_classes": ["", "", "", "", ""] });
    const [instrument, setInstrument] = useState({});
    function handleClose() {
        dispatch(changeStatusSheetWatchlist(false));
    }
    useEffect(() => {
        let instrument = sheetState.instrument
        setInstrument(sheetState.instrument)
        socketState.priceSocket.on("room" + instrument.token, (instrument) => {
            setInstrument(instrument)
        });

        return () => {
            // Unsubscribe from socket events
            socketState.priceSocket.off("room" + instrument.token);
        };
    }, [sheetState.instrument]);

    useEffect(() => {
        let totalSellQty = 0;
        let totalBuyQty = 0;
        let sellArray = [parseFloat(instrument.ask_qty), parseFloat(instrument.ask_qty2), parseFloat(instrument.ask_qty3), parseFloat(instrument.ask_qty4), parseFloat(instrument.ask_qty5)];
        let buyArray = [parseFloat(instrument.bid_qty), parseFloat(instrument.bid_qty2), parseFloat(instrument.bid_qty3), parseFloat(instrument.bid_qty4), parseFloat(instrument.bid_qty5)];
        sellArray.forEach((qty) => { totalSellQty += qty });
        buyArray.forEach((qty) => { totalBuyQty += qty });
        setDepthData({ "total_buy_qty": parseFloat(totalBuyQty).toFixed(2), "total_sell_qty": parseFloat(totalSellQty).toFixed(2), "buy_width_classes": calculatePercentageAndRound(buyArray), "sell_width_classes": calculatePercentageAndRound(sellArray) });
    }, [instrument]);


    function calculatePercentageAndRound(qtyArray) {
        // Find the maximum quantity
        const maxQty = Math.max(...qtyArray);

        // Calculate percentages and round them off to the nearest multiple of 10
        const percentages = qtyArray.map(qty => Math.round((qty / maxQty) * 100 / 10) * 10);

        // Generate classes
        const classes = percentages.map(percentage => `width-${percentage}`);

        return classes;
    }

    function pushOrderHandler(type) {
        dispatch(updateInstrumentPushOrder(type, instrument, instrument.lotsize));
        handleClose();
        history.push("/pushOrder");
    }

    if (!instrument) {
        return <></>
    }

    return <>
        <Offcanvas className="watchlistbuysell-popup" placement="bottom" show={sheetState.status} onHide={handleClose}>
            {/* <Offcanvas.Header closeButton>
            </Offcanvas.Header> */}
            <Offcanvas.Body className="p-0 bg-light border-radius-top-20">
                <div className="px-3 py-4">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <div>
                            <span className="d-block font-m fw-semibold d-flex align-items-center">{instrument.trading_symbol}<span className="label-white font-s text-black ms-2">{instrument.exchange}</span></span>
                            <span className="font-s font-red">Lots size : {instrument.lotsize}</span>
                        </div>
                        <div className="text-end">
                            <span className="d-block font-ml font-green fw-semibold">{instrument.ltp}</span>
                            <span className="font-s font-dark-grey">{instrument.price_change > 0 && '+'}{instrument.price_change} ({instrument.percentage_change}%)</span>
                        </div>
                    </div>
                    <div className="trading-balance-modal bg-white p-3 d-flex justify-content-between">
                        <div className="text-center"><span className="d-block font-m font-dark-grey">Open</span><span className="font-m">{instrument.open_price}</span></div>
                        <div className="text-center"><span className="d-block font-m font-dark-grey">High</span><span className="font-m">{instrument.high_price}</span></div>
                        <div className="text-center"><span className="d-block font-m font-dark-grey">Low</span><span className="font-m">{instrument.low_price}</span></div>
                        <div className="text-center"><span className="d-block font-m font-dark-grey">Close</span><span className="font-m">{instrument.close_price}</span></div>
                    </div>
                    <div style={{ overflowX: "hidden" }}>
                        <Table className="buysell-table">
                            <thead>
                                <tr>
                                    <th>QTY</th>
                                    <th>BUY PRICE</th>
                                    <th>SELL PRICE</th>
                                    <th>QTY</th>
                                </tr>
                            </thead>
                            <tbody>

                                <tr>
                                    <td>{instrument.bid_qty}</td>
                                    <td><div className={`graph  ${depthData["buy_width_classes"][0]}`}>{instrument.bid_price}</div></td>
                                    <td><div className={`graph red ${depthData["sell_width_classes"][0]}`}>{instrument.ask_price}</div></td>
                                    <td>{instrument.ask_qty}</td>
                                </tr>
                                <tr>
                                    <td>{instrument.bid_qty2}</td>
                                    <td><div className={`graph  ${depthData["buy_width_classes"][1]}`}>{instrument.bid_price2}</div></td>
                                    <td><div className={`graph red ${depthData["sell_width_classes"][1]}`}>{instrument.ask_price2}</div></td>
                                    <td>{instrument.ask_qty2}</td>
                                </tr>
                                <tr>
                                    <td>{instrument.bid_qty3}</td>
                                    <td><div className={`graph  ${depthData["buy_width_classes"][2]}`}>{instrument.bid_price3}</div></td>
                                    <td><div className={`graph red ${depthData["sell_width_classes"][2]}`}>{instrument.ask_price3}</div></td>
                                    <td>{instrument.ask_qty3}</td>
                                </tr>
                                <tr>
                                    <td>{instrument.bid_qty4}</td>
                                    <td><div className={`graph  ${depthData["buy_width_classes"][3]}`}>{instrument.bid_price4}</div></td>
                                    <td><div className={`graph red ${depthData["sell_width_classes"][3]}`}>{instrument.ask_price4}</div></td>
                                    <td>{instrument.ask_qty4}</td>
                                </tr>
                                <tr>
                                    <td>{instrument.bid_qty5}</td>
                                    <td><div className={`graph  ${depthData["buy_width_classes"][4]}`}>{instrument.bid_price5}</div></td>
                                    <td><div className={`graph red ${depthData["sell_width_classes"][4]}`}>{instrument.ask_price5}</div></td>
                                    <td>{instrument.ask_qty5}</td>
                                </tr>
                                <tr>
                                    <td>{depthData.total_buy_qty}</td>
                                    <td className="text-center" colSpan={2}>TOTAL QUANTITY</td>
                                    <td>{depthData.total_sell_qty}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                    {/* <div className="chart-button">
                        <Button className="chart-btn"> <img className="me-2" src="images/chart.svg" alt="chart" />Chart</Button>
                    </div> */}
                    <ul className="popup-lists">
                        <li>
                            <span className="font-m font-dark-grey">Volume</span>
                            <span className="font-m text-black">{instrument.volume}</span>
                        </li>

                        <li>
                            <span className="font-m font-dark-grey">Last traded quantity</span>
                            <span className="font-m text-black">{instrument.ltpq}</span>
                        </li>
                        <li>
                            <span className="font-m font-dark-grey">Last traded at</span>
                            <span className="font-m text-black">{timestampToDateTime(instrument.ltpt)}</span>
                        </li>
                        <li>
                            <span className="font-m font-dark-grey">Lower circuit</span>
                            <span className="font-m text-black">{instrument.lower_circuit}</span>
                        </li>
                        <li>
                            <span className="font-m font-dark-grey">Upper circuit</span>
                            <span className="font-m text-black">{instrument.upper_circuit}</span>
                        </li>
                    </ul>
                    <div className="portfolio-modal-button">
                        <Button className="buy-btn" onClick={() => { pushOrderHandler("buy") }}>
                            BUY
                            <span className="d-block">@{instrument.ask_price}</span>
                        </Button>
                        <Button className="sell-btn" onClick={() => { pushOrderHandler("sell") }}>
                            SELL
                            <span className="d-block">@{instrument.bid_price}</span>
                        </Button>
                    </div>
                </div>


            </Offcanvas.Body>

        </Offcanvas>
    </>
}