import { FETCH_CURRENCY_RATES } from "./actionTypes";
let initialState = {

};
const currencyRate = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CURRENCY_RATES:
      return action.payload;
    default:
      return state;
  }
};
export default currencyRate;
