import { CHANGE_STATUS_SHEET_PORTFOLIO, UPDATE_INSTRUMENT_SHEET_PORTFOLIO } from "./actionTypes";
let initialState = {
  "instrument": {},
  "position": {},
  "status": false
};
const bottomSheetPortfolio = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_STATUS_SHEET_PORTFOLIO:
      return {
        ...state,
        "status": action.payload
      };
    case UPDATE_INSTRUMENT_SHEET_PORTFOLIO:
      return {
        ...state,
        "instrument": action.payload.instrument,
        "position": action.payload.position
      };
    default:
      return state;
  }
};
export default bottomSheetPortfolio;
