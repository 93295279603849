import { Row, Col, Card } from 'react-bootstrap';
import { convertDateFormat, formatNumber } from '../../helper/general_helper';

export function IndexBox({ className }) {
    return <>
        <div className={"card border-0 bg-white " + className}>
            <div className='d-flex justify-content-center align-items-center px-5px'>
                <div className='mr-15px'><span className='icon-md icon-red'>SS</span></div>
                <div><span className='font-m'>Crypto/Btc</span><br /><span className='font-s font-red'>+232</span></div>
            </div>
        </div>
    </>;
}

export function TopGainer({ instrument, sheetChangingHandler }) {
    return <>
        <Card className="border-0 p-0 mb-2" onClick={() => { sheetChangingHandler(instrument) }}>
            <Card.Body className="px-3 py-2">
                <div className="d-flex justify-content-between align-items-center">
                    <div>
                        <span className="font-m fw-semibold d-block">{instrument.symbol}</span>
                        <span className="font-s font-dark-grey">{((instrument.expiry === "-") ? "-" : convertDateFormat(instrument.expiry))}</span>
                    </div>
                    <div className="text-center">
                        <span className="font-m fw-semibold d-block">{instrument.ltp}</span>
                        <span className="font-s font-dark-grey">Vol: {formatNumber(instrument.volume)}</span>
                    </div>
                    <div className="text-end">
                        <span className="font-m font-green fw-semibold d-block">+{instrument.price_change}</span>
                        <span className="font-s font-green">{instrument.percentage_change}%</span>
                    </div>
                </div>
            </Card.Body>
        </Card>
    </>
}

export function TopLooser({ instrument, sheetChangingHandler }) {
    return <>
        <Card className="border-0 p-0 mb-2" onClick={() => { sheetChangingHandler(instrument) }}>
            <Card.Body className="px-3 py-2">
                <div className="d-flex justify-content-between align-items-center">
                    <div>
                        <span className="font-m fw-semibold d-block">{instrument.symbol}</span>
                        <span className="font-s font-dark-grey">{((instrument.expiry === "-") ? "-" : convertDateFormat(instrument.expiry))}</span>
                    </div>
                    <div className="text-center">
                        <span className="font-m fw-semibold d-block">{instrument.ltp}</span>
                        <span className="font-s font-dark-grey">Vol: {formatNumber(instrument.volume)}</span>
                    </div>
                    <div className="text-end">
                        <span className="font-m font-red fw-semibold d-block">{instrument.price_change}</span>
                        <span className="font-s font-red">{instrument.percentage_change}%</span>
                    </div>
                </div>
            </Card.Body>
        </Card>
    </>
}