// import { RoundedButton } from '../../components/common/common.jsx';
// import { IndexBox, WatchlistBox } from '../../components/watchlist/smallComponents.jsx';
import React, { useState, useEffect } from 'react';
import { PositionBox, ModalBox } from '../../../components/portfolio/smallComponents.jsx';
import { Card, Tab, Nav, Collapse } from 'react-bootstrap';
import { useSelector, useDispatch } from "react-redux";
import { fetchPortfolio, changeCurrency } from "../../../store/portfolio/action";
import { Footer } from '../../../components/common/common.jsx';
import { BottomSheetPortfolio } from './BottomSheetPortfolio.jsx';
import { changeStatusSheetPortfolio, updateDataSheetPortfolio } from '../../../store/bottomSheetPortfolio/action.jsx';
import { CLOSE_ALL_POSITIONS_URL } from '../../../helper/url_helper.js';
import api from "../../../helper/api_helper";
import { toast } from "react-toastify";
import { EmptyPageImage } from '../../../components/common/common.jsx';

export default function Portfolio() {
    const dispatch = useDispatch();
    const loginState = useSelector((state) => state.login);
    const currencyRateState = useSelector((state) => state.currencyRate);
    const portfolioState = useSelector((state) => state.portfolio);
    const [positions, setPositions] = useState({ "INR": {}, "USD": {} });
    const socketState = useSelector((state) => state.socket);
    const [totalActivePl, setTotalActivePl] = useState(0);
    const [totalActivePlUSD, setTotalActivePlUSD] = useState(0);
    const [isCollapseOpen, setIsCollapseOpen] = useState(false); // State to manage collapse visibility
    // store instruments for bottom sheet
    const [instruments, setInstruments] = useState({});
    const [exitModalStatus, setExitModalStatus] = useState(false);
    // const markets = ["NFO", "MCX", "CRYPTO", "FX", "US"]
    useEffect(() => {
        dispatch(fetchPortfolio(loginState.jwttoken))
    }, [])

    useEffect(() => {
        let temp = {};
        let tempUSD = {};
        // set positions
        portfolioState.positions["INR"].forEach((position) => {
            temp[position.token] = position;
        });
        portfolioState.positions["USD"].forEach((position) => {
            tempUSD[position.token] = position;
        });
        setPositions({ "INR": temp, "USD": tempUSD });
        // subscribe positions for real time pl and cmp change
        portfolioState.positions["INR"].forEach((position) => {
            socketState.priceSocket.emit("tokenData", position.token);
            socketState.priceSocket.on("room" + position.token, (instrument) => {
                // update instrument for bottom sheet
                setInstruments((prevState) => ({ ...prevState, [instrument.token]: instrument }));
                //   update position here
                let profitAndLoss = 0;
                position.avg_price = parseFloat(position.avg_price);
                position.qty = parseFloat(position.qty);
                // position.cmp = parseFloat(position.cmp);
                instrument.bid_price = parseFloat(instrument.bid_price);
                instrument.ask_price = parseFloat(instrument.ask_price);
                instrument.ltp = parseFloat(instrument.ltp);

                let price = (position.type === "buy" && instrument.bid_price > 0) ? instrument.bid_price : (position.type === "sell" && instrument.ask_price > 0) ? instrument.ask_price : instrument.ltp;
                // console.log((price - position.avg_price) * position.qty);
                // console.log(price, position.avg_price, position.qty);
                if (position.type === "buy") {
                    profitAndLoss = (price - position.avg_price) * position.qty;
                } else {
                    profitAndLoss = (position.avg_price - price) * position.qty;
                }
                setPositions((prevState) => ({ "USD": { ...prevState["USD"] }, "INR": { ...prevState["INR"], [instrument.token]: { ...prevState["INR"][instrument.token], "active_profit_and_loss": parseFloat(profitAndLoss).toFixed(2), "cmp": price } } }))
            })
        });


        // subscribe positions for real time pl and cmp change
        portfolioState.positions["USD"].forEach((position) => {
            socketState.priceSocket.emit("tokenData", position.token);
            socketState.priceSocket.on("room" + position.token, (instrument) => {
                // update instrument for bottom sheet
                setInstruments((prevState) => ({ ...prevState, [instrument.token]: instrument }));
                //   update position here
                let profitAndLoss = 0;
                position.avg_price = parseFloat(position.avg_price);
                position.qty = parseFloat(position.qty);
                // position.cmp = parseFloat(position.cmp);
                instrument.bid_price = parseFloat(instrument.bid_price);
                instrument.ask_price = parseFloat(instrument.ask_price);
                instrument.ltp = parseFloat(instrument.ltp);

                let price = (position.type === "buy" && instrument.bid_price > 0) ? instrument.bid_price : (position.type === "sell" && instrument.ask_price > 0) ? instrument.ask_price : instrument.ltp;
                // console.log((price - position.avg_price) * position.qty);
                // console.log(price, position.avg_price, position.qty);
                if (position.type === "buy") {
                    profitAndLoss = (price - position.avg_price) * position.qty;
                } else {
                    profitAndLoss = (position.avg_price - price) * position.qty;
                }
                // change profit loss to usd
                if (instrument.currency != "INR") {
                    profitAndLoss *= parseFloat(currencyRateState[instrument.base_currency]);
                }
                setPositions((prevState) => ({ "INR": { ...prevState["INR"] }, "USD": { ...prevState["USD"], [instrument.token]: { ...prevState["USD"][instrument.token], "active_profit_and_loss": parseFloat(profitAndLoss).toFixed(2), "cmp": price } } }));
            })
        });

        // console.log(positions)

        return () => {
            // Unsubscribe from socket events
            portfolioState.positions["INR"].forEach((position) => {
                socketState.priceSocket.off("room" + position.token);
            });
            portfolioState.positions["USD"].forEach((position) => {
                socketState.priceSocket.off("room" + position.token);
            });
        };
    }, [portfolioState.positions]);

    useEffect(() => {
        // console.log("sd");
        let temp = 0;
        Object.keys(positions["INR"]).forEach(key => {
            temp += parseFloat(positions["INR"][key].active_profit_and_loss);
        });
        setTotalActivePl(temp.toFixed(2));

        temp = 0;
        Object.keys(positions["USD"]).forEach(key => {
            temp += parseFloat(positions["USD"][key].active_profit_and_loss);
        });
        setTotalActivePlUSD(temp.toFixed(2));
    }, [positions]);


    function changeCurrencyHandler(currency) {
        dispatch(changeCurrency(currency))
    }


    function sheetChangingHandler(instrument, position) {
        dispatch(updateDataSheetPortfolio(instrument, position));
        dispatch(changeStatusSheetPortfolio(true));
    }

    async function closeAllPositions() {
        try {
            api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
            let data = {
                "currency": portfolioState.currency
            };
            let res = await api.post(CLOSE_ALL_POSITIONS_URL, data);
            if (res.data.status === "SUCCESS") {
                toast.success("Positions exit successfully");
                setExitModalStatus(false);
            } else {
                toast.error(res.data.message);
                setExitModalStatus(false);
            }
        } catch (error) {
            toast.error(error.response.data.message);
            setExitModalStatus(false);
        }
    }

    return <>
        <div className="screen">
            <ModalBox show={exitModalStatus} handleYes={closeAllPositions} onHide={() => { setExitModalStatus(false) }} />
            <BottomSheetPortfolio />
            <div className="main-content bg-light px-0">
                <div className="px-3">
                    <Card className="border-0 bg-transparent p-0 mb-3">
                        <Card.Body className="d-flex justify-content-between align-items-center p-0">
                            <div className="col-6">
                                <span className="fw-semibold">Portfolio</span>
                            </div>
                            <div className="col-5">
                                <Tab.Container id="tabs-example">
                                    <Nav className="d-flex justify-content-end orders-tab" variant="tabs">
                                        <Nav.Item>
                                            <Nav.Link eventKey="inr" className={(portfolioState.currency === "INR") ? "active" : ""} onClick={() => { changeCurrencyHandler("INR") }}>INR</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="forex" className={(portfolioState.currency === "USD") ? "active" : ""} onClick={() => { changeCurrencyHandler("USD") }}>Forex</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                    {/* <Tab.Content>
                                        <Tab.Pane eventKey="inr">

                                        </Tab.Pane>
                                        <Tab.Pane eventKey="forex">

                                        </Tab.Pane>
                                    </Tab.Content> */}
                                </Tab.Container>
                                {/* <button className='btn bg-blue'>INR</button> */}
                            </div>
                        </Card.Body>
                    </Card>
                </div>
                <div className="px-3">
                    <Card className="border-0 p-0 mb-3">
                        <Card.Body className="d-flex justify-content-center align-items-center flex-column">
                            {(portfolioState.currency === "INR") && <span className={`${totalActivePl > 0 ? "font-green" : "font-red"} font-l d-block`}>{(totalActivePl > 0) && "+"}{totalActivePl}</span>}
                            {(portfolioState.currency === "USD") && <span className={`${totalActivePlUSD > 0 ? "font-green" : "font-red"} font-l d-block`}>{(totalActivePlUSD > 0) && "+"}{totalActivePlUSD}</span>}
                            <span className="font-dark-grey font-m">Total P&L</span>
                        </Card.Body>
                    </Card>
                </div>
                <div className="px-3">
                    <Card className="border-0 bg-transparent p-0 mb-3">
                        <Card.Body className="d-flex justify-content-between align-items-center p-0">
                            <span className="font-m fw-semibold">Trading Balance</span>
                            <div className="label label-blue font-s" onClick={() => { setIsCollapseOpen(!isCollapseOpen) }}>View <i className="font-s fa-regular fa-eye"></i></div>
                        </Card.Body>
                    </Card>
                </div>
                <Collapse in={isCollapseOpen}>
                    <>
                        {(portfolioState.currency === "INR") && <div className="trading-balance-card bg-white p-3 d-flex">
                            <div className="text-center flex-grow-1"><span className='font-m'>{portfolioState.balance}</span><br /><span className="font-m font-dark-grey">Ledger</span></div>
                            <div className="border-left-1 flex-grow-1 mx-2"></div>
                            <div className="text-center flex-grow-1"><span className='font-m'>{(parseFloat(portfolioState.balance) - parseFloat(portfolioState.used_balance)).toFixed(2)}</span><br /><span className=" font-m font-dark-grey">Margin Available</span></div>
                            <div className="border-left-1 flex-grow-1 text-center mx-2"></div>
                            <div className="text-center flex-grow-1"><span className='font-m'>{portfolioState.used_balance}</span><br /><span className="font-m font-dark-grey">Margin Used</span></div>
                        </div>}

                        {(portfolioState.currency === "USD") && <div className="trading-balance-card bg-white p-3 d-flex">
                            <div className="text-center flex-grow-1"><span className='font-m'>{portfolioState.balance_usd}</span><br /><span className="font-m font-dark-grey">Ledger</span></div>
                            <div className="border-left-1 flex-grow-1 mx-2"></div>
                            <div className="text-center flex-grow-1"><span className='font-m'>{(parseFloat(portfolioState.balance_usd) - parseFloat(portfolioState.used_balance_usd).toFixed(2))}</span><br /><span className=" font-m font-dark-grey">Margin Available</span></div>
                            <div className="border-left-1 flex-grow-1 text-center mx-2"></div>
                            <div className="text-center flex-grow-1"><span className='font-m'>{portfolioState.used_balance_usd}</span><br /><span className="font-m font-dark-grey">Margin Used</span></div>
                        </div>}
                    </>
                </Collapse>
                {(portfolioState.currency === "INR") && <div className="d-flex justify-content-between bg-blue-light py-11 px-3">
                    <div><span className="font-m font-dark-grey me-2">M2M:</span><span className='font-m font-green'>{(parseFloat(portfolioState.balance) + parseFloat(totalActivePl)).toFixed(2)}</span></div>
                    <div><span className="font-m font-dark-grey me-2">Holding:</span><span className='font-m font-red'>{portfolioState.cnc_margin_req}</span></div>
                </div>}

                {(portfolioState.currency === "USD") && <div className="d-flex justify-content-between bg-blue-light py-11 px-3">
                    <div><span className="font-m font-dark-grey me-2">M2M:</span><span className='font-m font-green'>{(parseFloat(portfolioState.balance_usd) + parseFloat(totalActivePlUSD)).toFixed(2)}</span></div>
                    <div><span className="font-m font-dark-grey me-2">Holding:</span><span className='font-m font-red'>{portfolioState.cnc_margin_req_usd}</span></div>
                </div>}

                <div className="px-3 pt-4">
                    <Card className="border-0 bg-transparent p-0 mb-3">
                        <Card.Body className="d-flex justify-content-between align-items-center p-0">
                            <span className="font-m fw-semibold">Positions</span>
                            <div className="label label-red font-s" onClick={() => { setExitModalStatus(true) }}>Close All  <i clasName="font-s fa-regular fa-eye"></i></div>
                        </Card.Body>
                    </Card>
                </div>
                <div className="px-3">
                    {((Object.keys(positions[portfolioState.currency]).length === 0)) &&
                        <EmptyPageImage />
                    }

                    {Object.keys(positions[portfolioState.currency]).map((key) => {
                        return <PositionBox key={key} instrument={instruments[key]} position={positions[portfolioState.currency][key]} sheetChangingHandler={sheetChangingHandler} />
                    })}
                </div>

            </div >

            <Footer />
            {/* <div className="footer">
                <Tab.Container id="tabs-example" defaultActiveKey="portfolio">
                    <Nav className="d-flex justify-content-between footer-tabs" variant="tabs">
                        <Nav.Item>
                            <Nav.Link eventKey="market"><div className="mb-2"><img src="images/market.svg" alt="market" /></div>Market</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="watchlist"><div className="mb-2"><img src="images/watchlist.svg" alt="watchlist" /></div>Watchlist</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="portfolio"><div className="mb-2"><img src="images/portfolio.svg" alt="portfolio" /></div>Portfolio</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="order"><div className="mb-2"><img src="images/order.svg" alt="order" /></div>Order</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="account"><div className="mb-2"><img src="images/account.svg" alt="account" /></div>Account</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <Tab.Content>
                        <Tab.Pane eventKey="market">
                            <div>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="watchlist">
                            <div>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="portfolio">
                            <div>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="order">
                            <div>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="account">
                            <div>
                            </div>
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </div> */}
        </div >
    </>
}