import { SET_FORGOT_DATA } from "./actionTypes";

export const setData = (data, key) => {
  return async (dispatch) => {
    dispatch({ type: SET_FORGOT_DATA, payload: { data: data, key: key } });
  };
};

// export const fetchData = (jwttoken) => {
//   return async (dispatch) => {
//     try {
//       api.defaults.headers.common["Authorization"] = `Bearer ${jwttoken}`;
//       let res = await api.get(FETCH_BILL_HISTORY_URL);
//       if (res.data.status === "SUCCESS") {
//         // toast.success(res.data.message);
//         dispatch({ type: FETCH_BILL_HISTORY, payload: res.data.payload });
//       }
//     } catch (error) {
//       toast.error(error.message);
//     }
//   };
// };
