import React, { useEffect } from "react";
import { Card } from 'react-bootstrap';
import { useSelector, useDispatch } from "react-redux";
import { fetchData } from "../../../store/rejectionLog/action";
import { EmptyPageImage } from "../../../components/common/common";
import { useHistory } from 'react-router-dom';

const AccountRejectionLogs = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const loginState = useSelector((state) => state.login);
    const rejectionLogsState = useSelector((state) => state.rejectionLogs);
    useEffect(() => {
        dispatch(fetchData(loginState.jwttoken))
    }, [])

    return (
        <div className="screen">
            <div className="main-content bg-light">
                <span class="d-flex align-items-center fw-semibold gap-3 mb-30" onClick={() => { history.goBack() }}>
                    <img src="images/left-arrow.svg" alt="left-arrow"></img>Rejection logs
                </span>
                {((rejectionLogsState.logs).length === 0) &&
                    <EmptyPageImage />
                }
                {rejectionLogsState.logs.map((log) => {
                    return <>
                        <Card className="border-0 p-0 mb-2 w-100" key={log.id}>
                            <Card.Body className="p-3">
                                <span className="d-block mb-0 font-m">{log.message}</span>
                                <span className="font-dark-grey font-s">{log.time}</span>
                            </Card.Body>
                        </Card>
                    </>
                })}

                {/* <Card className="border-0 p-0 mb-2 w-100">
                    <Card.Body className="p-3">
                        <span className="d-block mb-0 font-m">max lot GOLD50CTFUT can trade 20</span>
                        <span className="font-dark-grey font-s">05 JUN 23 19:57:42</span>
                    </Card.Body>
                </Card>
                <Card className="border-0 p-0 mb-2 w-100">
                    <Card.Body className="p-3">
                        <span className="d-block mb-0 font-m">max lot GOLD50CTFUT can trade 20</span>
                        <span className="font-dark-grey font-s">05 JUN 23 19:57:42</span>
                    </Card.Body>
                </Card>
                <Card className="border-0 p-0 mb-2 w-100">
                    <Card.Body className="p-3">
                        <span className="d-block mb-0 font-m">max lot GOLD50CTFUT can trade 20</span>
                        <span className="font-dark-grey font-s">05 JUN 23 19:57:42</span>
                    </Card.Body>
                </Card>
                <Card className="border-0 p-0 mb-2 w-100">
                    <Card.Body className="p-3">
                        <span className="d-block mb-0 font-m">max lot GOLD50CTFUT can trade 20</span>
                        <span className="font-dark-grey font-s">05 JUN 23 19:57:42</span>
                    </Card.Body>
                </Card>
                <Card className="border-0 p-0 mb-2 w-100">
                    <Card.Body className="p-3">
                        <span className="d-block mb-0 font-m">max lot GOLD50CTFUT can trade 20</span>
                        <span className="font-dark-grey font-s">05 JUN 23 19:57:42</span>
                    </Card.Body>
                </Card>
                <Card className="border-0 p-0 mb-2 w-100">
                    <Card.Body className="p-3">
                        <span className="d-block mb-0 font-m">max lot GOLD50CTFUT can trade 20</span>
                        <span className="font-dark-grey font-s">05 JUN 23 19:57:42</span>
                    </Card.Body>
                </Card>
                <Card className="border-0 p-0 mb-2 w-100">
                    <Card.Body className="p-3">
                        <span className="d-block mb-0 font-m">max lot GOLD50CTFUT can trade 20</span>
                        <span className="font-dark-grey font-s">05 JUN 23 19:57:42</span>
                    </Card.Body>
                </Card> */}
            </div>
        </div>
    );
};

export default AccountRejectionLogs;
