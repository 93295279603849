import { CHANGE_STATUS_SHEET_WATCHLIST, UPDATE_INSTRUMENT_SHEET_WATCHLIST } from "./actionTypes";

export const changeStatusSheetWatchlist = (status) => {
  return async (dispatch) => {
    dispatch({ type: CHANGE_STATUS_SHEET_WATCHLIST, payload: status });
  };
};

export const updateInstrumentSheetWatchlist = (instrument) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_INSTRUMENT_SHEET_WATCHLIST, payload: instrument });
  };
};
