import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import api from "../../../helper/api_helper";
import { toast } from "react-toastify";
import { useHistory } from 'react-router-dom';
import { FORGOT_PASSWORD_URL } from '../../../helper/url_helper';
import { useSelector, useDispatch } from "react-redux";
import { setData as setForgotData } from '../../../store/forgot/action';

export default function ForgotPassword() {
    const dispatch = useDispatch();
    const history = useHistory();
    const [email, setEmail] = useState('');
    // send otp on email
    // submit and move on next page
    async function submit() {
        try {
            if (email === "") {
                toast.error("email required");
                return;
            }
            let res = await api.post(FORGOT_PASSWORD_URL, { "email": email });
            if (res.data.status === "SUCCESS") {
                // console.log(res.data);
                dispatch(setForgotData(email, "email"));
                history.push("verification-forgot");
            }
        } catch (error) {
            toast.error(error.response.data.message);
        }
    }
    // validate otp 
    // change password
    return <>
        <div className="screen">
            <div className="main-content bg-white">
                <div className="d-flex justify-content-center flex-column h-100">
                    <img className="mb-30" src="images/forgotpassword-image.png" alt="forgotpassword-image" />
                    <p className="medium-title">Forgot password</p>
                    <p className="text mb-30">Don’t worry ! It happens. Please enter the email we will send the OTP in this email address.</p>
                    <div className="form-wrapper">
                        <Form>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label className="custom-label">Email</Form.Label>
                                <Form.Control className="custom-form-control" size="lg" value={email} onChange={(e) => { setEmail(e.target.value) }} type="email" placeholder="name@example.com" />
                            </Form.Group>
                            <div className="mt-30">
                                <Button className="signin-btn w-100" onClick={submit}>Send</Button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>

    </>
}