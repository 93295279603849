import { FETCH_WATCHLIST } from "./actionTypes";
let initialState = {
  watchlist: {
    "NFO": [],
    "MCX": [],
    "CDS": [],
    "CRYPTO": [],
    "FX": [],
    "US": []
  },
};
const watchlist = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_WATCHLIST:
      return {
        ...state,
        watchlist: action.payload
      };
    default:
      return state;
  }
};
export default watchlist;
