export function convertDateFormat(inputDate) {
    // Create a Date object from the input date string
    const date = new Date(inputDate);

    // Array of month names
    const monthNames = [
        "JAN", "FEB", "MAR", "APR", "MAY", "JUN",
        "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"
    ];

    // Extract day, month, and year components from the date object
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear().toString().substr(-2); // Get the last two digits of the year

    // Format the date in the desired format
    const formattedDate = `${day}-${monthNames[monthIndex]}-${year}`;

    return formattedDate;
}

export function convertDateFormatWithSpaces(dateString) {
    // Create a new Date object from the given dateString
    const date = new Date(dateString);

    // Define months array
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    // Get the components of the date
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear().toString().slice(-2);

    // Return the formatted date string
    return `${day} ${month} ${year}`;
}


export function formatNumber(num) {
    if (num >= 1000000) {
        return (num / 1000000).toFixed(1) + 'M';
    }
    if (num >= 1000) {
        return (num / 1000).toFixed(1) + 'K';
    }
    return num.toString();
}

export function timestampToDateTime(timestamp) {
    // Convert timestamp to milliseconds
    var date = new Date(timestamp * 1000);

    // Get year, month, day, hours, minutes, and seconds from the date object
    var year = date.getFullYear();
    var month = ("0" + (date.getMonth() + 1)).slice(-2);
    var day = ("0" + date.getDate()).slice(-2);
    var hours = date.getHours();
    var minutes = ("0" + date.getMinutes()).slice(-2);
    var seconds = ("0" + date.getSeconds()).slice(-2);

    // Format the date and time as YYYY-MM-DD HH:MM:SS
    var formattedDateTime = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;

    return formattedDateTime;
}