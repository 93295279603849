import { FETCH_REJECTION_lOGS } from "./actionTypes";
let initialState = {
  "logs": []
};
const rejectionLogs = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_REJECTION_lOGS:
      return {
        "logs": action.payload
      };
    default:
      return state;
  }
};
export default rejectionLogs;
